import { createPinia } from 'pinia';
import { createApp } from 'vue/dist/vue.esm-bundler.js';
import { createI18n } from 'vue-i18n';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import generalTranslationsDe from './i18n/de';
import generalTranslationsEn from './i18n/en';
import feTranslationsDe from './modules/fe/i18n/de';
import feTranslationsEn from './modules/fe/i18n/en';
import _upperFirst from 'lodash/upperFirst';
import _camelCase from 'lodash/camelCase';

window.__ = (key, data = null) => i18n.global.t(key, data);
window.axios = axios;

const app = createApp({
    data() {
        return {
            navOpen: false,
        };
    },
    methods: {},
});

const componentFiles = import.meta.glob(['./components/*.vue', './modules/fe/components/*.vue'], {
    eager: true,
});

Object.entries(componentFiles).forEach(([path, m]) => {
    const componentName = _upperFirst(
        _camelCase(
            path
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    );

    app.component(componentName, m.default);
});

const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard',
            currencyDisplay: 'symbol',
        },
    },
    de: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            useGrouping: true,
            currencyDisplay: 'symbol',
        },
    },
};

const i18n = createI18n({
    numberFormats,
    legacy: false,
    globalInjection: true,
    locale: document.documentElement.lang,
    fallbackLocale: document.documentElement.lang === 'en' ? 'de' : 'en',
    messages: {
        de: Object.assign(generalTranslationsDe, feTranslationsDe, {}),
        en: Object.assign(generalTranslationsEn, feTranslationsEn, {}),
    },
});

if (import.meta.env.VITE_VUE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_VUE_SENTRY_DSN,
    });
    Sentry.setTag('product', 'Deck');
}

app.directive('click-outside', {
    mounted: function mounted(el, binding) {
        el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                if (typeof binding.value === 'function') {
                    binding.value(event, el);
                } else {
                    console.info('[click-outside] Value have to be a function');
                }
            }
        };
        document.addEventListener('mousedown', el.clickOutsideEvent);
    },
    unmounted: function unmounted(el) {
        document.removeEventListener('mousedown', el.clickOutsideEvent);
    },
});

app.use(createPinia()).use(i18n);
app.mount('#app');

addEventListener('DOMContentLoaded', (event) => {
    const header = document.querySelector('.header');
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    if (header && ((month === 12 && day >= 30) || (month === 1 && day <= 1))) {
        for (let i = 0; i < 10; i++) {
            const firework = document.createElement('div');
            firework.classList.add('firework');
            firework.style.animationDelay = `${Math.random() * 5}s`;
            firework.style.left = `${Math.random() * 100}%`;
            firework.style.top = `${Math.random() * 100}%`;
            header.appendChild(firework);
        }
    }
});

// Polyfill globalThis: https://mathiasbynens.be/notes/globalthis
(function () {
    if (typeof globalThis === 'object') return;
    Object.defineProperty(Object.prototype, '__magic__', {
        get: function () {
            return this;
        },
        configurable: true,
    });
    __magic__.globalThis = __magic__;
    delete Object.prototype.__magic__;
})();
