export const contact = {
    mail: {
        info: 'info@23M.com',
        sales: 'sales@23M.com',
        finance: 'finance@23M.com',
        support: 'support@23M.com',
        noc: 'noc@23M.com',
        jobs: 'jobs@23M.com',
    },
    phone: {
        general: {
            densed: '+492519339440',
            pretty: '+49 251 933 944 0',
        },
    },
    fax: {
        general: {
            densed: '+4925193394499',
            pretty: '+49 251 933 944 99',
        },
    },
};

export const external23MServices = {
    statusPage: {
        url: 'https://status.23m.com',
    },
    helpCenter: {
        url: 'https://service.23m.com',
        ticketIndexUrl: 'https://service.23m.com/hc/requests',
    },
    s3ManagementInterface: {
        url: 'https://cmc.23m.com',
        endpoint: 's3-fra.23m.com',
        bucketEndpoint: '[Bucketname].s3-fra.23m.com',
    },
    cloudManagementInterface: {
        url: 'https://cloud.23m.com/',
    },
    domain: {
        url: 'https://domain.23m.com',
    },
};
