<template>
    <BaseForm data-spec-class="ticket-form">
        <BaseAlertErrorServer
            v-if="hasServerErrors"
            :size="Size.Small"
            class="mb-4"
            data-spec-class="ticket-form__server-error"
        />
        <div class="grid grid-cols-6 gap-6">
            <div class="col-span-6">
                <BaseFieldSelect
                    name="topic"
                    :label="t('forms.ticket.topic.label')"
                    :options="topicOptions"
                    v-model="formFields.topic"
                    :vuelidate-field="v$.topic"
                />
            </div>
            <div class="col-span-6">
                <BaseFieldInput
                    name="subject"
                    :label="t('forms.ticket.subject.label')"
                    v-model="formFields.subject"
                    :vuelidate-field="v$.subject"
                />
            </div>
            <div class="col-span-6">
                <BaseFieldTextarea
                    name="description"
                    :label="t('forms.ticket.description.label')"
                    v-model="formFields.description"
                    :vuelidate-field="v$.description"
                />
            </div>

            <div class="col-span-6">
                <BaseField
                    name="file"
                    :label="t('forms.ticket.uploads.label')"
                    :error-messages="[
                        ...(ticketStore.uploadFileValidationError?.file || []),
                        ...fileVuelidateErrors,
                    ]"
                    :isOptional="true"
                    data-spec-class="ticket-form__file"
                >
                    <div
                        class="relative rounded-md border border-gray-300 bg-none p-3 text-center text-gray-300 hover:bg-gray-100"
                    >
                        {{ t('actions.addFile') }}
                        <input
                            name="file"
                            class="absolute inset-0 h-full w-full cursor-pointer opacity-0 focus:border-blue-500 focus:ring-blue-500"
                            :type="TextFieldType.File"
                            @change="onFileChange"
                            data-spec-class="ticket-form__file-input"
                        />
                    </div>
                </BaseField>

                <div
                    class="mt-3 grid grid-cols-2 gap-x-3 gap-y-3"
                    data-spec-class="ticket-form__ticket-files"
                >
                    <div
                        class="flex rounded-full bg-gray-100 px-3 py-1"
                        v-for="file in ticketStore.getFiles"
                        :key="file.id"
                        data-spec-class="ticket-form__ticket-file"
                    >
                        <PaperClipIcon class="mr-2 h-5 w-5" />
                        <a
                            :href="file.url"
                            class="underline hover:text-gray-400"
                            target="_blank"
                            data-spec-class="ticket-form__ticket-file-link"
                        >
                            {{ file.name }}
                        </a>
                        <a
                            class="ml-auto flex items-center justify-center"
                            data-spec-class="ticket-form__ticket-file-delete"
                        >
                            <XCircleIcon
                                class="ml-2 h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-400"
                                @click.prevent="onTicketFileDelete(file)"
                            />
                        </a>
                    </div>
                    <div
                        v-if="ticketStore.isUploadingFile"
                        class="p-1"
                    >
                        <BaseSpinner class="h-5 w-5" />
                    </div>
                </div>
            </div>
        </div>

        <template #submit>
            <BaseButton
                :is-loading="ticketStore.isCreatingTicket"
                :is-disabled="v$.$error"
                @click.prevent="onSubmit"
                data-spec-class="ticket-form__submit"
                class="-my-5 w-full sm:w-fit"
            >
                {{ t('actions.create') }}
            </BaseButton>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { defineEmits, computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { required, fileSize50 } from '../i18n/i18n-validators';
import { useToast } from '../composables/toast';
import { Size, TextFieldType } from '../../../types';
import { useTicketStore } from '../stores/ticketStore';
import BaseFieldTextarea from '../../../components/BaseFieldTextarea.vue';
import { useTicket } from '../composables/ticket';
import BaseFieldInput from '../../../components/BaseFieldInput.vue';
import { PaperClipIcon, XCircleIcon } from '@heroicons/vue/20/solid';

const { t } = useI18n();
const { addSuccessToast, addErrorToast } = useToast();
const ticketStore = useTicketStore();
const { formFields, resetFormFields } = useTicket();

const emits = defineEmits(['submit']);
interface Props {
    subjectValue?: string;
}

const props = withDefaults(defineProps<Props>(), {
    subjectValue: '',
});

onMounted(() => {
    if (props.subjectValue && !formFields.subject.includes(props.subjectValue)) {
        formFields.subject = props.subjectValue;
    }
});

const hasServerErrors = ref(false);

const rules = computed(() => ({
    topic: { required },
    subject: { required },
    description: { required },
    file: { fileSize50: fileSize50() },
}));

const v$ = useVuelidate(rules, formFields);

const onSubmit = async () => {
    const isFileFieldCorrect = await v$.value.$validate();
    if (!isFileFieldCorrect) return;

    try {
        await ticketStore.createTicket({
            topic: formFields.topic,
            subject: formFields.subject,
            description: formFields.description,
            fileTokens: ticketStore.getFiles.map((file) => file.token),
        });
        if (ticketStore.createTicketValidationError) {
            return;
        }
        hasServerErrors.value = false;
        emits('submit');
        resetFormFields();
        addSuccessToast(t('messages.success.saved'));
    } catch (error) {
        hasServerErrors.value = true;
    }
};

const topicOptions = computed(() => [
    { key: 'finance', label: t('resources.ticket.enums.topic.finance') },
    { key: 'sales', label: t('resources.ticket.enums.topic.sales') },
    { key: 'technic', label: t('resources.ticket.enums.topic.technic') },
    { key: 'emergency', label: t('resources.ticket.enums.topic.emergency') },
    { key: 'miscellaneous', label: t('resources.ticket.enums.topic.miscellaneous') },
]);

const onFileChange = async (event) => {
    const files = event.target.files;
    formFields.file = files[0];
    const isFileFieldCorrect = await v$.value.file.$validate();
    if (!isFileFieldCorrect) return;

    if (files.length > 0) {
        const file = files[0];
        try {
            await ticketStore.uploadFile(file);
        } catch (error) {
            addErrorToast(t('errors.serverError.message'));
        }
    }
};

const onTicketFileDelete = async (file) => {
    try {
        await ticketStore.destroyUploadedFile(file);
    } catch (error) {
        addErrorToast(t('errors.serverError.message'));
    }
};

const fileVuelidateErrors = computed(() => {
    if (v$.value.file.$errors.length) {
        return v$.value.file.$errors.map((error) => error.$message);
    }

    return [];
});
</script>
