/* eslint-disable import/prefer-default-export */
import { useI18n } from 'vue-i18n';
import { reactive } from 'vue';
import { Attribute, MarketingBanner } from '../../../types';

export const errors = {
    unconfirmedPassword: 'Unconfirmed password.',
};
export const NO_DATA_SYMBOL = '–';

const coreState = reactive({
    csrf: '',
    statamicPages: {
        terms: undefined as string | undefined,
        privacy: undefined as string | undefined,
        blog: undefined as string | undefined,
    },
    marketingBanners: [] as MarketingBanner[],
    isFeatureChangelogAvailable:
        import.meta.env.VITE_VUE_IS_FEATURE_CHANGELOG_AVAILABLE === 'true' ? true : false,
    isFeatureTicketsAvailable:
        import.meta.env.VITE_VUE_IS_FEATURE_TICKETS_AVAILABLE === 'true' ? true : false,
    isFeatureOrderApproveAvailable:
        import.meta.env.VITE_VUE_IS_FEATURE_ORDER_APPROVE_AVAILABLE === 'true' ? true : false,
    isMobileSidebarOpen: false,
    isMaintenanceModeActive: false,
    isSessionExpired: false,
    isPasswordConfirmationRequired: false,
    isPasswordConfirmed: null as boolean | null,
});

const waitForValueToChange = (valueGetter, checkInterval = 100) =>
    new Promise((resolve) => {
        let currentValue = valueGetter();
        const intervalId = setInterval(() => {
            const newValue = valueGetter();
            if (newValue !== currentValue) {
                currentValue = newValue;
                clearInterval(intervalId);
                resolve(currentValue);
            }
        }, checkInterval);
    });

export function useCoreSetup() {
    const { t } = useI18n();

    function diffInWords(date1: Date | string, date2: Date | string | null = null) {
        let dateStart = new Date();
        let dateEnd = new Date();

        if (date2 === null) {
            date2 = date1;
            date1 = new Date();
        }

        if (typeof date1 === 'string') dateStart = new Date(date1);
        if (typeof date2 === 'string') dateEnd = new Date(date2);

        const diffTime = Math.abs(dateEnd.getTime() - dateStart.getTime());

        const diffInSeconds = Math.floor((diffTime / 1000) % 60);
        const diffInMinutes = Math.floor((diffTime / 1000 / 60) % 60);
        const diffInHours = Math.floor((diffTime / (1000 * 60 * 60)) % 24);
        const diffInDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const diffInWeeks = Math.floor(diffInDays / 7);
        const diffInMonths = Math.floor(diffInDays / 30);

        if (diffInMonths > 0) {
            return t('general.diffInMonths', { count: diffInMonths });
        } else if (diffInWeeks > 0) {
            return t('general.diffInWeeks', { count: diffInWeeks });
        } else if (diffInDays > 0) {
            return t('general.diffInDays', { count: diffInDays });
        } else if (diffInHours > 0) {
            return t('general.diffInHours', { count: diffInHours });
        } else if (diffInMinutes > 0) {
            return t('general.diffInMinutes', { count: diffInMinutes });
        } else {
            return t('general.diffInSeconds', { count: diffInSeconds });
        }
    }

    function buildAttributes(attributes: Attribute[], name: string): Attribute[] {
        return attributes
            .map((attribute: Attribute) => ({
                ...attribute,
                label: t(`resources.${name}.attributes.${attribute.name}`),
            }))
            .sort((a, b) => a.position - b.position);
    }

    return {
        coreState,
        buildAttributes,
        diffInWords,
    };
}

export function useCore() {
    const updatePageTitle = (title: string) => {
        if (title) {
            document.title = `${title} | 23M`;
        } else {
            document.title = '23M';
        }
    };

    const openSidebarMobile = () => {
        coreState.isMobileSidebarOpen = true;
    };

    const closeSidebarMobile = () => {
        coreState.isMobileSidebarOpen = false;
    };

    const confirmPassword = async () => {
        try {
            coreState.isPasswordConfirmationRequired = true;
            const isPasswordConfirmed = await waitForValueToChange(
                () => coreState.isPasswordConfirmed
            );
            return Promise.resolve(isPasswordConfirmed);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    return {
        coreState,
        updatePageTitle,
        openSidebarMobile,
        closeSidebarMobile,
        confirmPassword,
    };
}
