<template>
    <li
        class="relative flex gap-x-4"
        data-spec-class="feed-comment"
    >
        <div class="absolute -bottom-6 left-0 top-0 flex w-12 justify-center">
            <div class="w-px bg-gray-200"></div>
        </div>
        <img
            v-if="avatarUrl"
            :src="avatarUrl"
            :alt="avatarAlt"
            class="bg-gray-50 relative mt-3 h-12 w-12 flex-none rounded-full"
        />
        <div
            v-else
            class="relative mt-3 flex h-12 w-12 flex-none items-center justify-center rounded-full bg-gray-200"
        >
            <UserIcon class="h-10 w-10 text-white" />
        </div>

        <div :class="classObject">
            <div class="flex justify-between gap-x-4">
                <div class="py-0.5 text-sm text-gray-500">
                    <span class="font-medium text-gray-900">{{ authorName }}</span>
                    {{ t('pages.TicketPage.commented') }}
                </div>
                <div class="flex-none py-0.5 text-sm text-gray-500">{{ diffInWords(date) }}</div>
            </div>
            <p
                class="text-sm/6 text-gray-700"
                v-html="formattedMessage"
            ></p>
            <slot name="append"></slot>
        </div>
    </li>
</template>

<script setup lang="ts">
import { useCoreSetup } from '../modules/your/composables/core';
import { UserIcon } from '@heroicons/vue/20/solid';
import { computed } from 'vue';
import { FeedCommentTheme } from '../types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { diffInWords } = useCoreSetup();

interface Props {
    authorName: string;
    date: string;
    comment: string;
    avatarUrl?: string;
    avatarAlt?: string;
    theme?: FeedCommentTheme;
}

const props = withDefaults(defineProps<Props>(), {
    avatarUrl: undefined,
    avatarAlt: undefined,
    theme: FeedCommentTheme.Customer,
});

const formattedMessage = computed(() => {
    let commentWithLineBreaks = props.comment.replace(/\n/g, '<br />');
    commentWithLineBreaks = commentWithLineBreaks.replace(/\r/g, '<br />');
    commentWithLineBreaks = commentWithLineBreaks.replace('<br /><br /><br /><br /><br />', '');

    if (commentWithLineBreaks.includes('<br />--<br />')) {
        return commentWithLineBreaks.split('<br />--<br />')[0];
    }
    if (commentWithLineBreaks.includes('<br />-- <br />')) {
        return commentWithLineBreaks.split('<br />-- <br />')[0];
    }
    if (
        commentWithLineBreaks.includes('<br />____________________________________________<br />')
    ) {
        return commentWithLineBreaks.split(
            '<br />____________________________________________<br />'
        )[0];
    }

    return commentWithLineBreaks;
});

const classObject = {
    'flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200': true,
    'bg-white': props.theme === FeedCommentTheme.Customer,
    'bg-gray-100': props.theme === FeedCommentTheme.Agent,
};
</script>
