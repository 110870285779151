<template>
    <BaseDialog
        :is-open="isOpen"
        :size="Size.Small"
        @close="$emit('close')"
    >
        <BaseDialogTitle data-spec-class="ticket-dialog_form__title">
            {{ t('resources.ticket.actions.create') }}
        </BaseDialogTitle>
        <BaseDialogBody>
            <TicketForm
                :subject-value="subjectValue"
                @submit="$emit('close')"
            />
        </BaseDialogBody>
    </BaseDialog>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { Size } from '../../../types';
import TicketForm from './TicketForm.vue';

const { t } = useI18n();
interface Props {
    isOpen?: boolean;
    subjectValue?: string;
}

withDefaults(defineProps<Props>(), {
    isOpen: false,
    subjectValue: '',
});

defineEmits(['close', 'submit']);
</script>
