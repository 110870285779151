import { sum } from '../../../utils/arrayUtils';
import { reactive, computed, watch } from 'vue';
import { getAllUrlParams } from '../../../utils/windowUtils';
import { deepEqualObjects } from '../../../utils/objectUtils';
import { useAssortmentMailSummary } from './assortmentSummary';
import { useAssortmentNetwork } from './assortmentNetwork';
import { useAssortmentIp } from './assortmentIp';

export function useAssortmentDedicated(
    assortmentContent,
    assortmentNetworkContent,
    assortmentIpContent
) {
    const { uplinkAssortment, trafficAssortment } = useAssortmentNetwork(assortmentNetworkContent);
    const {
        ipV4NetworkAssortment,
        ipV6NetworkAssortment,
        ipV4AddressAssortment,
        getNumberOfPayedIpv4Addresses,
    } = useAssortmentIp(assortmentIpContent);

    const fields = reactive({
        deviceId: null,
        ramCapacity: null,
        storageIds: {},
        uplinkId: null,

        volumeId: 1,
        ipv4Addresses: 1,
        ipv4Subnet: null, // TODO: change to ipv4SubnetId when all configurators are updated
        ipv6Subnet: null, // TODO: change to ipv6SubnetId when all configurators are updated
    });

    const assortment = reactive({
        devices: [],

        uplinks: [],
        volumes: [],
        ipv4Address: {},
        ipv4Subnets: [],
        ipv6Subnets: [],
    });

    const selection = computed(() => {
        const device = assortment.devices.find(
            (device) => String(device.id) === String(fields.deviceId)
        );

        let storageQuantityItems = [];
        Object.entries(fields.storageIds).forEach(([id, quantity]) => {
            if (quantity === 0) {
                return;
            }
            const storageQuantityItem = {
                quantity,
                storage: device?.components.storage[0].options.find(
                    (storage) => String(storage.id) === String(id)
                ),
            };
            if (storageQuantityItem.storage) {
                storageQuantityItems.push(storageQuantityItem);
            }
        });
        storageQuantityItems = storageQuantityItems.sort((a, b) => a.storage.sort - b.storage.sort);

        return {
            device: device,
            ram: device?.components.ram[0].options.find(
                (ram) => String(ram.capacity) === String(fields.ramCapacity)
            ),
            uplink: Object.values(device?.uplinks).find(
                (uplink) => String(uplink.id) === String(fields.uplinkId)
            ),
            storages: storageQuantityItems,

            volume: assortment.volumes[fields.volumeId],
            ipv4Addresses: fields.ipv4Addresses,
            ipv4Subnet: assortment.ipv4Subnets[fields.ipv4Subnet] || null,
            ipv6Subnet: assortment.ipv6Subnets[fields.ipv6Subnet] || null,
        };
    });

    const numberOfPayedIpv4Addresses = computed(() =>
        getNumberOfPayedIpv4Addresses(selection.value.ipv4Addresses)
    );

    const monthlyPrices = computed(() => {
        const prices = {};

        const device = selection.value.device;
        prices.device = device.price.monthly_price;

        prices.ram = selection.value.ram?.monthly_price ?? 0;
        prices.storages = selection.value.storages
            .map((storage) => storage.storage.price.monthly_price * storage.quantity)
            .reduce((a, b) => a + b, 0);
        prices.uplink = selection.value.uplink?.monthly_price ?? 0;
        prices.volume = selection.value.volume?.monthly_price ?? 0;

        prices.ipv4Addresses = numberOfPayedIpv4Addresses.value * assortment.ipv4Address.price;
        prices.ipv4Subnet = selection.value.ipv4Subnet?.monthly_price ?? 0;
        prices.ipv6Subnet = selection.value.ipv6Subnet?.monthly_price ?? 0;

        return prices;
    });

    const monthlyPrice = computed(() => {
        return sum(Object.values(monthlyPrices.value));
    });

    const setupPrices = computed(() => {
        const prices = {};

        prices.uplink = selection.value.uplink?.setup_price ?? 0;
        prices.volume = selection.value.volume?.setup_price ?? 0;
        prices.ipv4Addresses = numberOfPayedIpv4Addresses.value * assortment.ipv4Address.setupPrice;
        prices.ipv4Subnet = selection.value.ipv4Subnet?.setup_price ?? 0;
        prices.ipv6Subnet = selection.value.ipv6Subnet?.setup_price ?? 0;
        return prices;
    });

    const setupPrice = computed(() => {
        return sum(Object.values(setupPrices.value));
    });

    const initAssortment = (deviceAssortment) => {
        assortment.devices = deviceAssortment.sort((a, b) => a.sort - b.sort);
        assortment.devices.forEach((device) => {
            device.components.ram[0].options = device.components.ram[0].options.sort(
                (a, b) => a.sort - b.sort
            );
            device.uplinks = device.uplinks
                .map((uplinkId) => {
                    return uplinkAssortment[uplinkId];
                })
                .sort((a, b) => a.max_volume - b.max_volume);
        });

        assortment.uplinks = uplinkAssortment;
        assortment.volumes = trafficAssortment;

        assortment.ipv4Address = ipV4AddressAssortment;
        assortment.ipv4Subnets = ipV4NetworkAssortment;
        assortment.ipv6Subnets = ipV6NetworkAssortment;
    };

    const initDeviceDefaultFields = (device) => {
        fields.ramCapacity = device.defaults.ram[0].capacity;
        fields.uplinkId = device.uplinks[0].id;
        fields.storageIds = {};
        device.defaults.storage.forEach((storage) => {
            fields.storageIds[storage.id] = storage.quantity;
        });
    };

    const initFields = (device) => {
        fields.deviceId = device.id;
        initDeviceDefaultFields(device);
    };

    const initFieldsByUrl = () => {
        const urlParams = getAllUrlParams();
        if (urlParams.device) {
            fields.deviceId = parseInt(urlParams.device);
        }
        if (urlParams.ram) {
            fields.ramCapacity = urlParams.ram;
        }

        if (urlParams.storages) {
            Object.entries(urlParams.storages).forEach((storageEntry) => {
                const storageId = storageEntry[0];
                const quantity = parseInt(storageEntry[1]);
                if (quantity === 0) {
                    return;
                }
                fields.storageIds[storageId] = quantity;
            });
        }

        if (urlParams.uplink) {
            fields.uplinkId = parseInt(urlParams.uplink);
        }
        if (urlParams.volume) {
            fields.volumeId = parseInt(urlParams.volume);
        }

        if (urlParams.ipv4Addresses) {
            fields.ipv4Addresses = parseInt(urlParams.ipv4Addresses);
            fields.ipv4Subnet = null;
            fields.ipv6Subnet = null;
        }
        if (urlParams.ipv4Subnet) {
            fields.ipv4Subnet = parseInt(urlParams.ipv4Subnet);
            fields.ipv4Addresses = 0;
        }
        if (urlParams.ipv6Subnet) {
            fields.ipv6Subnet = parseInt(urlParams.ipv6Subnet);
            fields.ipv4Addresses = 0;
        }
    };

    const mailSummary = computed(() => {
        const summaries = {};
        summaries.device = `Device: ${selection.value.device?.name} (${selection.value.device?.cpu?.name})`;
        summaries.ram = `RAM: ${selection.value.ram?.capacity} GB`;

        const storageQuantityItemSummaries = selection.value.storages?.map(
            (storageQuantityItem) => {
                return `${storageQuantityItem.quantity}x ${storageQuantityItem.storage.name}`;
            }
        );
        summaries.storages = `Storages: ${storageQuantityItemSummaries.join(', ')}`;

        const { networkMailSummary, ipMailSummary } = useAssortmentMailSummary(selection);
        summaries.network = networkMailSummary.value;
        summaries.ip = ipMailSummary.value;

        return Object.values(summaries).join('\n');
    });

    watch(
        () => fields.deviceId,
        (newValue, oldValue) => {
            initDeviceDefaultFields(selection.value.device);
        }
    );

    const deviceAssortment = JSON.parse(assortmentContent);

    initAssortment(deviceAssortment);
    initFields(deviceAssortment[0]);
    initFieldsByUrl();

    const decorateCpuSpecs = (cpu) => {
        return `${cpu.cores} Cores, ${cpu.threads} Threads, ${cpu.frequency} GHz`;
    };

    const usedStorageSlots = computed(() => {
        let usedSlots = 0;
        for (const id in selection.value.storages) {
            usedSlots += selection.value.storages[id].quantity;
        }
        return usedSlots;
    });

    const freeStorageSlots = computed(() => {
        return selection.value.device?.components.storage[0].size - usedStorageSlots.value;
    });

    const storagesByType = computed(() => {
        return groupAndSortStorages(selection.value.device.components.storage[0].options);
    });

    const groupAndSortStorages = (storages) => {
        const storagesByType = {};
        storages.forEach((storage) => {
            if (storagesByType[storage.type] === undefined) {
                storagesByType[storage.type] = [];
            }
            storagesByType[storage.type].push(storage);
        });
        Object.entries(storagesByType).forEach(([type, storages]) => {
            storagesByType[type] = storages.sort((a, b) => a.sort - b.sort);
        });
        return storagesByType;
    };

    const isDefaultConfig = computed(() => {
        const ramCheck = selection.value.device?.defaults.ram
            .map((ram) => ram.capacity)
            .includes(selection.value.ram.capacity);

        const defaultStorageTuples = selection.value.device?.defaults.storage.map((storage) => ({
            id: storage.id,
            quantity: storage.quantity,
        }));
        const selectedStorageTuples = selection.value.storages.map((storage) => ({
            id: storage.storage.id,
            quantity: storage.quantity,
        }));
        const storageCheck = deepEqualObjects(defaultStorageTuples, selectedStorageTuples);

        return ramCheck && storageCheck;
    });

    return {
        assortment,
        fields,
        selection,
        decorateCpuSpecs,
        storagesByType,
        usedStorageSlots,
        freeStorageSlots,
        numberOfPayedIpv4Addresses,
        monthlyPrices,
        monthlyPrice,
        setupPrices,
        setupPrice,
        isDefaultConfig,
        mailSummary,
    };
}
